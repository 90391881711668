// vars to override sidebar component with
$sidebar-bg-color: #e9e9e9ea;
$sidebar-color: #000000;
$sidebar-width: 270px;
$sidebar-collapsed-width: 80px;
$highlight-color: #000000;
$submenu-bg-color: #e9e9e9ea;
$submenu-bg-color-collapsed: #a85353;
$icon-bg-color: #dbd3c7;
$icon-size: 35px;
$submenu-indent: 24px;
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;
// end temp vars

@import '~react-pro-sidebar/dist/scss/styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Island+Moments&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arbutus&display=swap');

.sidebarHeader {
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pro-sidebar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sidebarHeaderOpen {
    height: 5%;
    display: grid;
    place-items: center center;
}

.displayedText {
    grid-column: 1;
    font-size: 21px;
    font-weight: bold;
    font-family: 'Island+Moments', cursive;
}

.FaAngleDoubleLeft {
    grid-column: 2;
    cursor: pointer;    
}

.FaAngleDoubleRight {
    cursor: pointer;
}

.toggleLayer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}
.toggleLayerDescription {
    place-self: center left;
}
.onoffswitch {
    position: relative; width: 90px;
    place-self: center left;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 15px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 12px;
    background-color: #1bcc04; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 12px;
    background-color: #d11a1a; color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 16px; margin: 7px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0px;
    right: 56px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}